<template>
    <div>
      <el-select
        :value="selected"
        @input="dispatch"
        :placeholder="placeholder || $t('message.type')"
        filterable
        clearable
        :size="size"
        class="w-100"
      >
        <el-option
          v-for="(documentType, index) in documentTypes"
          :key="'documentType-' + index"
          :label="documentType.name"
          :value="documentType.key"
        ></el-option>
      </el-select>
    </div>
  </template>
  <script>
  import { mapGetters } from "vuex";
  export default {
    props: {
      size: {
        default: "small",
      },
      placeholder: {
        default: null,
      },
      id: {
        default: null,
      },
    },
    watch: {
      id: {
        handler: function () {
          this.selected = this.id;
        },
        immediate: true,
      },
    },
    data() {
      return {
        selected: null,
      };
    },
    computed: {
      ...mapGetters({
        documentTypes: "documentTemplates/documentTypes",
      }),
    },
    methods: {
      dispatch(e) {
        this.$emit("input", e);
        this.selected = e;
      },
    },
  };
  </script>
  