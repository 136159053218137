<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0"> {{
              $t("message.edit_m", {
                m: $t("message.documentTemplate"),
              })
            }}</p>
          <div>
             <crm-store-update-close
                :permission="$options.name"
                :button_type="'store'"
                :loading="loadingButton"
                @c-submit="submit(true)"
                @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4">
            <el-form ref="form" :model="form" :rules="rules" >
                <el-row :gutter="20">
                    <el-col :span="24">
                      <el-form-item
                      prop="name"
                      :label="$t('message.name')"
                      >
                        <el-input
                            :placeholder="$t('message.name')"
                            v-model="form.name"
                            size="medium"
                        ></el-input>
                      </el-form-item>     
                    </el-col>
                    <el-col :span="24">
                      <el-form-item
                      prop="type"
                      :label="$t('message.type')"
                      >
                        <select-type
                          :size="'medium'"
                          :placeholder="$t('message.type')"
                          :id="form.type"
                          v-model="form.type"
                        >
                        </select-type>
                      </el-form-item>     
                    </el-col>
                </el-row>
            </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";
import SelectType from "@/components/inventory/select-document-type";

export default {
    name: "documentTemplate",
    mixins: [form, drawer, show],
    components: {
      SelectType
    },
    data() {
        return {};
    },
    created() {},
    computed: {
        ...mapGetters({
            rules: "documentTemplates/rules",
            model: "documentTemplates/model",
            columns: "documentTemplates/columns",
        }),
    },
    methods: {
        ...mapActions({
            update: "documentTemplates/update",
            show: "documentTemplates/show",
        }),
        submit(close = true) {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.loadingButton = true;
                    this.update(this.form)
                        .then((res) => {
                            this.loadingButton = false;
                            this.$alert(res);
                            this.parent().listChanged();
                            if (res.status == 200) this.close();
                        })
                        .catch((err) => {
                            this.loadingButton = false;
                            this.$alert(err);
                        });
                }
            });
        },
    },
};
</script>
