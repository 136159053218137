<template>
  <div class="">
    <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="caz-blocks-sarcho-title">
              <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2"> {{ $t("message.documentTemplates") }} </div>
              <div class="block-sarche">
                <div class="header__search">
                  
                   <crm-input
                        :size="'small'"
                        :class="mode ? 'input__day' : 'input__night'"
                        :className="'w100'"
                        v-model="filterForm.search"
                        :icon="'el-icon-search'"
                    ></crm-input>
                </div>
              </div>
            </div>
          </el-col>

          <el-col :span="12" class="flex-style text-right">

             <crm-create-and-column-settings
                  :permission="$options.name"
                  :class="mode ? 'button__settingsday' : 'button__settingsnight'"
                  @c-create="drawerCreate = true"
                  :columns="columns"
                  @c-change="updateColumn"
              >
              </crm-create-and-column-settings>
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->

      <div class="card-table-header table-crm-smart">
        <table 
          class="table-my-code table-bordered"
          :class="mode ? 'table__myday' : 'table__mynight'"
          v-loading="loadingData"
        >
          <thead>
            <tr>
                <th class="w50p" v-if="columns.id.show">
                    {{ columns.id.title }}
                </th>

                <th v-if="columns.name.show">
                    {{ columns.name.title }}
                </th>

                <th v-if="columns.type.show">
                    {{ columns.type.title }}
                </th>

                <th v-if="columns.created_at.show">
                    {{ columns.created_at.title }}
                </th>

                <th v-if="columns.updated_at.show">
                    {{ columns.updated_at.title }}
                </th>

                <th v-if="columns.settings.show">
                    {{ columns.settings.title }}
                </th>
            </tr>

            <tr class="filter_sorche">
              <th v-if="columns.id.show">
                  <el-input
                      clearable
                      size="mini"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.id"
                      :placeholder="columns.id.title"
                      class="id_input"
                  ></el-input>
              </th>

              <th v-if="columns.name.show">
                  <crm-input
                      :placeholder="columns.name.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.name"
                  ></crm-input>
              </th>

              <th v-if="columns.type.show">
                  <select-type
                    :size="'mini'"
                    :placeholder="$t('message.type')"
                    v-model="filterForm.type"
                  >
                  </select-type>
              </th>

              <th v-if="columns.created_at.show">
                  <crm-date-picker
                      :placeholder="columns.created_at.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.created_at"
                  ></crm-date-picker>
              </th>

              <th v-if="columns.updated_at.show">
                  <crm-date-picker
                      :placeholder="columns.updated_at.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.updated_at"
                  ></crm-date-picker>
              </th>

              <th
                  class="settinW"
                  v-if="columns.settings.show"
              ></th>
          </tr>
          </thead>

          <transition-group name="flip-list" tag="tbody">
            <tr v-for="doc_template in list" :key="doc_template.id" class="cursor-pointer">

                <td v-if="columns.id.show">
                    {{ doc_template.id }}
                </td>

                <td v-if="columns.name.show">
                    {{ doc_template.name }}
                </td>

                <td v-if="columns.type.show">
                    {{  getType(doc_template.type) }}
                </td>
               
                <td v-if="columns.created_at.show">
                    {{ doc_template.created_at }}
                </td>

                <td v-if="columns.updated_at.show">
                    {{ doc_template.updated_at }}
                </td>

                <td v-if="columns.settings.show" class="settings-td">
                     <crm-settings
                        :name="$options.name"
                        :model="doc_template"
                        :actions="actions"
                        :permissionShow="'documentTemplates.update'"
                        :permissionDestroy="'documentTemplates.delete'"
                        @show="show"
                        @edit="edit"
                        @delete="destroy"
                    ></crm-settings>
                </td>
            </tr>
          </transition-group>
        </table>
        <div class="my___pagination">
          <crm-pagination
            @c-change="updatePagination"
            :class="mode ? 'pagination__day' : 'pagination__night'"
            :pagination="pagination"
          ></crm-pagination>
        </div>
      </div>

        <div class="app-modal app-modal__full modal-color-bg">
            <el-drawer
               :with-header="false"  
               :visible.sync="drawerCreate" 
               ref="drawerCreate"
               size="70%"
               @opened="drawerOpened('drawerCreateChild')"
               @closed="drawerClosed('drawerCreateChild')"
               >
                <div>
                    <crm-create ref="drawerCreateChild" drawer="drawerCreate"> </crm-create>
                </div>
            </el-drawer>

            <el-drawer
                :with-header="false"
                :visible.sync="drawerUpdate"
                size="70%"
                ref="drawerUpdate"
                @opened="drawerOpened('drawerUpdateChild')"
                @closed="drawerClosed('drawerUpdateChild')"
            >
                <crm-update
                    :selectedItem="selectedItem"
                    ref="drawerUpdateChild"
                    drawer="drawerUpdate"
                ></crm-update>
            </el-drawer> 
        </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import list from "@/utils/mixins/list";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
import { mapGetters, mapActions } from "vuex";
import SelectType from "@/components/inventory/select-document-type";

export default {
    name: "documentTemplates",
    mixins: [list],
    components: {
        SelectType,
        CrmCreate,
        CrmUpdate,
    },

    data() {
        return {

        };
    },

    computed: {
        ...mapGetters({
            list: "documentTemplates/list",
            columns: "documentTemplates/columns",
            pagination: "documentTemplates/pagination",            
            filter: "documentTemplates/filter",
            sort: "documentTemplates/sort",
            mode: "MODE",
            documentTypes: "documentTemplates/documentTypes",
        }),
        actions: function () {
            return ["show", "edit", "delete"];
        },
    },
    methods: {
        ...mapActions({
            updateList: "documentTemplates/index",
            setPagination: "documentTemplates/setPagination",
            updateSort: "documentTemplates/updateSort",
            updateFilter: "documentTemplates/updateFilter",
            updateColumn: "documentTemplates/updateColumn",
            updatePagination: "documentTemplates/updatePagination",
            empty: "documentTemplates/empty",
            delete: "documentTemplates/destroy",
            refreshData: "documentTemplates/refreshData",
        }),
        getType(key) {
            let el = this.documentTypes.find(el => el.key == key);
            return el ? el.name : '';
        },
        show(model) {
            this.selectedItem = model;
            this.$openOfficeForTemplate('edit', model);
        },
    
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('documentTemplates/EMPTY_LIST');
        next()
    },
};
</script>

